import { IDocument } from '%/entities/document';
import { IError } from '%/entities/error';
import { deflatObject } from '%/utils/flat-object';
import { createDocumentValidate } from '%/validation/document';
import { Checkbox } from '~/shared/components/controls/checkbox';
import { Form } from '~/shared/components/controls/form';
import { IFormRenderProps } from '~/shared/components/controls/form/d';
import { FormRow } from '~/shared/components/controls/form/form-row';
import { Input } from '~/shared/components/controls/input';
import { Delimeter } from '~/shared/components/delimeter';
import { IEntityForm } from '~/shared/components/entities/d';
import { useNotification } from '~/shared/components/notification';
import { useDocumentsActions } from '~/shared/hooks/use-documents-actions';

const FORM_ID = 'createDocumentProcess';

export const DocumentForm:React.FC<IEntityForm<IDocument>> = ({ initial = {} as IDocument, onSubmit }) => {
  const { createDocument, updateDocument } = useDocumentsActions();
  const { addSendingId, removeSendingId } = useNotification();

  const submitHandler = async (values:IDocument) => {
    const action = values.id ? updateDocument : createDocument;
    addSendingId(FORM_ID);
    const resp:IError|IDocument = await action(values);
    removeSendingId(FORM_ID);

    if ((resp as IError).errors) {
      return deflatObject((resp as IError).errors);
    }

    onSubmit?.(resp);
  };

  return (
    <Form<IDocument>
      onSubmit={submitHandler}
      validate={createDocumentValidate}
      submitBtn="Сохранить"
      formId={FORM_ID}
      initial={initial}
      restoreValues={!initial.id}
    >
      { ({ errors, initial }:IFormRenderProps<IDocument>) => (
        <>
          { initial.id &&
            <Input type="hidden" name="id" defaultValue={initial.id}/>
          }
          <FormRow>
            <Input
              label="Название"
              name="title"
              errors={errors.title}
              defaultValue={initial.title}/>
            <Input
              label="Тип документа"
              name="docType"
              errors={errors.docType}
              defaultValue={initial.docType}/>
            <Input
              label="Название в тексте ошибки"
              name="errorTitle"
              errors={errors.errorTitle}
              defaultValue={initial.errorTitle}/>
          </FormRow>
          <Delimeter size="m"/>
          <FormRow>
            <Checkbox
              name="disabled"
              label="Скрытый"
              defaultChecked={initial.disabled}
            />
          </FormRow>
        </>
      )}
    </Form>
  );
};