import cn from 'classnames';
import { SyntheticEvent, useDeferredValue, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IFilterInput } from './d';

import filterIcon from '@css-theme/icons/filter.svg';

import styles from './filter-input.module.styl';

export const FilterInput:React.FC<IFilterInput> = ({ onInput }) => {
  const location = useLocation();
  const inputRef = useRef<HTMLDivElement>(null);
  const [filter, setFilter] = useState<string>('');
  const filterQuery = useDeferredValue(filter);
  const handleChange = (e:SyntheticEvent<HTMLDivElement>) => {
    const val = e.currentTarget.textContent?.toLowerCase() || '';
    setFilter(val);
    onInput(val);
  };

  useEffect(() => {
    handleChange({ currentTarget: { textContent: null } } as SyntheticEvent<HTMLDivElement>);
    if (inputRef.current) {
      inputRef.current.innerText = '';
    }
  }, [location]);

  return (
    <div className={cn(styles.root, filterQuery ? styles.filled : '')}>
      <div className={styles.input} contentEditable onInput={handleChange} ref={inputRef}/>
      <span className={styles.icon}>
        <img src={filterIcon}/>
      </span>
    </div>
  );
};