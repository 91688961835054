import { IOffer } from '%/entities/offer';
import { IUser } from '%/entities/user';
import { generatePath, getViewAbsoluteUrl } from '~/core/views';
import { IEntitiesList } from '~/shared/components/entities/d';
import { Tiles } from '~/shared/components/tiles';
import { ITile } from '~/shared/components/tiles/d';
import { getUserNameInitials } from '~/shared/tools/get-name-initials';

const castItemToTile = (item:IOffer):ITile<IOffer> => ({
  id: item.id,
  icon: item.offerCompany?.logo as string,
  title: getUserNameInitials(item.data as IUser),
  subtitle: item.data?.phone,
  description: item.offerCompany?.title,
  url: `${generatePath(getViewAbsoluteUrl('entities', 'edit'), { entity: 'offers', id: item.id })}`,
  urlState: { initial: item }
});

export const Offers:React.FC<IEntitiesList<IOffer>> = ({ items }) => {
  return <Tiles<IOffer> items={items.map(item => castItemToTile(item))}/>;
};