import { ITiles } from './d';
import { Tile } from './item';

import styles from './tiles.module.styl';

export const Tiles = <T, >({ items }:ITiles<T>) => {
  return (
    <div className={styles.root}>
      { items.map(item => (
        <Tile {...item} key={item.id}/>
      ))}
    </div>
  );
};