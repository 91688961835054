import { useEffect, useState } from 'react';

import { IDocumentSet } from '%/entities/documents-set';
import { IEntityList } from '~/shared/components/entities/d';
import { useDictionary } from '~/shared/hooks/use-dictionary';
import { useDocumentsSetActions } from '~/shared/hooks/use-documents-set-actions';
import { sortData } from '~/shared/tools/sorting';

import { DocumentsSets as DocumentsSetsTable } from './table';
import { DocumentsSets as DocumentsSetsTiles } from './tiles';

export const DocumentsSets = ({ view, sortingOrder, sortingType, filter }:IEntityList) => {
  const { documentsList } = useDictionary();
  const [initial, setInitialData] = useState<IDocumentSet[]>([]);
  const [data, setData] = useState<IDocumentSet[]>([]);
  const { getDocumentsSets } = useDocumentsSetActions();

  const init = async () => {
    const data = await getDocumentsSets();
    if (!data.errorStatus) {
      const mappedData = data.map((set:IDocumentSet) => ({
        ...set,
        mappedDocs: set.docs.map(
          docType => `${documentsList.find(doc => doc.docType === docType)?.title}: <b>${docType}</b>`
        )
      }));
      setInitialData([...mappedData]);
      setData(mappedData);
    }
  };

  useEffect(() => {
    !!documentsList?.length && init();
  }, [documentsList]);

  useEffect(() => {
    const filtered = filter ? initial.filter(el => el.title?.toLowerCase().includes(filter)) : [...initial];

    const sorted = sortData<IDocumentSet>({ type: sortingType, order: sortingOrder, data: filtered });
    setData([...sorted]);
  }, [sortingOrder, sortingType, filter]);

  return view === 'table' ?
    <DocumentsSetsTable items={data} reload={init}/> :
    <DocumentsSetsTiles items={data}/>;
};