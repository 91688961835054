import { useLocation, useNavigate } from 'react-router-dom';

import { ICompany } from '%/entities/company';
import { generatePath, getViewAbsoluteUrl } from '~/core/views';
import { mapCompaniesToOption } from '~/shared/components/companies/utils';
import { Select } from '~/shared/components/controls/select';
import { ISelectProps } from '~/shared/components/controls/select/d';
import { useCompanyActions } from '~/shared/hooks/use-company-actions';

type ISelectCompanyProps = ISelectProps<ICompany> & { entityFrom:string }

export const SelectCompany = <T extends { companyId:string }, >({
  entityFrom, ...props
}:ISelectCompanyProps) => {
  const navigate = useNavigate();
  const { getCompanies } = useCompanyActions();
  const { state } = useLocation();
  const { navigation } = state || {};

  const goToAdd = () => {
    const params:IParamsToReturn<T, ICompany> = [{ name: 'companyId', value: 'id' }];
    const stateNavigation = navigation ? [...navigation] : [];
    stateNavigation.push({
      paramsToReturn: params,
      backUrl: generatePath(getViewAbsoluteUrl('entities', 'add'), { entity: entityFrom })
    } as INavigation<T, ICompany>);
    navigate(generatePath(getViewAbsoluteUrl('entities', 'add'), { entity: 'companies' }), {
      state: { navigation: stateNavigation }
    });
  };

  return (
    <Select<ICompany>
      {...props}
      loadOptions={getCompanies}
      mapOption={mapCompaniesToOption}
      actionOptions={[{
        text: '+ Добавить',
        name: 'addCompany',
        onClick: goToAdd
      }]}
    />
  );
};