import { IDocument } from '%/entities/document';
import { generatePath, getViewAbsoluteUrl } from '~/core/views';
import { IEntitiesList } from '~/shared/components/entities/d';
import { Tiles } from '~/shared/components/tiles';
import { ITile } from '~/shared/components/tiles/d';

const castItemToTile = (item:IDocument):ITile<IDocument> => ({
  id: item.id as string,
  title: item.title as string,
  subtitle: item.docType,
  description: item.disabled ? 'Не активен' : 'Активен',
  url: `${generatePath(getViewAbsoluteUrl('entities', 'edit'), { entity: 'documents', id: item.id as string })}`,
  urlState: { initial: item }
});

export const Documents:React.FC<IEntitiesList<IDocument>> = ({ items }) => {
  return <Tiles<IDocument> items={items.map(item => castItemToTile(item))}/>;
};