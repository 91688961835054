import { ICompany } from '%/entities/company';
import { generatePath, getViewAbsoluteUrl } from '~/core/views';
import { IEntitiesList } from '~/shared/components/entities/d';
import { Tiles } from '~/shared/components/tiles';
import { ITile } from '~/shared/components/tiles/d';

const castItemToTile = (item:ICompany):ITile<ICompany> => ({
  id: item.id as string,
  icon: item.logo as string,
  title: item.title,
  subtitle: item.phone,
  description: item.email,
  url: `${generatePath(getViewAbsoluteUrl('entities', 'edit'), { entity: 'companies', id: item.id as string })}`,
  urlState: { initial: item }
});

export const Companies:React.FC<IEntitiesList<ICompany>> = ({ items }) => {
  return <Tiles<ICompany> items={items.map(item => castItemToTile(item))}/>;
};