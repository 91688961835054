import { useEffect, useState } from 'react';

import { IDocument } from '%/entities/document';
import { IEntityList } from '~/shared/components/entities/d';
import { useDictionary } from '~/shared/hooks/use-dictionary';
import { sortData } from '~/shared/tools/sorting';

import { Documents as DocumentsTable } from './table';
import { Documents as DocumentsTiles } from './tiles';

export const Documents = ({ view, sortingOrder, sortingType, filter }:IEntityList) => {
  const { documentsList, reloadDocumentsList } = useDictionary();
  const [data, setData] = useState<IDocument[]>(documentsList);

  useEffect(() => {
    const filtered = filter ? documentsList.filter(el => el.title?.toLowerCase().includes(filter)) : [...documentsList];
    const sorted = sortData<IDocument>({ type: sortingType, order: sortingOrder, data: filtered });
    setData([...sorted]);
  }, [sortingOrder, sortingType, filter, documentsList]);

  useEffect(() => {
    reloadDocumentsList();
  }, []);

  return view === 'table' ?
    <DocumentsTable items={data} reload={reloadDocumentsList}/> :
    <DocumentsTiles items={data}/>;
};