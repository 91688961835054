import { ChangeEvent, useEffect, useState } from 'react';

import { IDocument } from '%/entities/document';
import { IDocumentSet } from '%/entities/documents-set';
import { IError } from '%/entities/error';
import { IOffer } from '%/entities/offer';
import { deflatObject } from '%/utils/flat-object';
import { isAdmin } from '%/utils/user';
import { IValidationErrors } from '%/validation/d';
import { offerValidate } from '%/validation/offer';
import { Checkbox } from '~/shared/components/controls/checkbox';
import { CheckboxGroup } from '~/shared/components/controls/checkbox-group';
import { Form } from '~/shared/components/controls/form';
import { IFormRenderProps } from '~/shared/components/controls/form/d';
import { FormRow } from '~/shared/components/controls/form/form-row';
import { changeFormValue, getValue } from '~/shared/components/controls/form/utils';
import { Input } from '~/shared/components/controls/input';
import { SelectCompany } from '~/shared/components/controls/select-company';
import { Textarea } from '~/shared/components/controls/textarea';
import { Delimeter } from '~/shared/components/delimeter';
import { IEntityForm } from '~/shared/components/entities/d';
import { useNotification } from '~/shared/components/notification';
import { useDictionary } from '~/shared/hooks/use-dictionary';
import { useDocumentsSetActions } from '~/shared/hooks/use-documents-set-actions';
import { useOfferActions } from '~/shared/hooks/use-offer-actions';
import { useUser } from '~/shared/hooks/use-user';

const FORM_ID = 'createOfferProcess';

export const OfferCreateForm:React.FC<IEntityForm<IOffer>> = ({ onSubmit }) => {
  const [sets, setDocSets] = useState<IDocumentSet[]>([]);
  const { user } = useUser();
  const { documentsList } = useDictionary();
  const { createOffer } = useOfferActions();
  const { addSendingId, removeSendingId } = useNotification();
  const { getDocumentsSets } = useDocumentsSetActions();

  const loadDocumentsSets = async () => {
    const data = await getDocumentsSets();
    if (!data.errorStatus) {
      const mappedData = data.map((set:IDocumentSet) => ({
        ...set,
        mappedDocs: set.docs.map(docType => documentsList.find(doc => doc.docType === docType)?.title)
      }));
      setDocSets(mappedData);
    }
  };

  const handleDocsSetChange = (event:ChangeEvent<HTMLInputElement>) => {
    const checked = getValue(event.target.name);
    const checkedDocs:string[] = [];

    checked.forEach((el:string) => {
      const checkedSet:IDocumentSet|undefined = sets.find((set:IDocumentSet) => set.id === el);
      if (checkedSet) {
        checkedDocs.push(...(checkedSet as IDocumentSet).docs);
      }
    });

    changeFormValue('data.documents[]', checkedDocs);
  };

  const submitHandler = async (values:IOffer) => {
    const reqBody:IOffer = {
      ...values,
      data: {
        ...values.data,
        documents: values.data?.documents?.map(docType => ({ docType })) as IDocument[]
      }
    };

    addSendingId(FORM_ID);
    const resp:IOffer|IError = await createOffer(reqBody, false);
    removeSendingId(FORM_ID);

    if ((resp as IError).errors) {
      return deflatObject((resp as IError).errors);
    }
    onSubmit?.(resp);
  };

  const validate = (values:IOffer):IValidationErrors<IOffer> => {
    const data:IOffer = {
      ...values,
      data: {
        ...values.data,
        documents: values.data?.documents?.map(docType => ({ docType })) as IDocument[]
      }
    };
    return offerValidate(data, documentsList);
  };

  useEffect(() => {
    documentsList?.length && loadDocumentsSets();
  }, [documentsList]);

  return (
    <Form<IOffer>
      onSubmit={submitHandler}
      validate={validate}
      submitBtn="Сгенерировать ссылку"
      formId={FORM_ID}
      restoreValues
    >
      { ({ errors, initial, formChangeTrigger, values }:IFormRenderProps<IOffer>) => (
        <>
          <Input
            type="hidden"
            name="type"
            defaultValue={initial.type || 'documents'}
          />
          {!isAdmin(user) &&
            <Input
              type="hidden"
              name="companyId"
              defaultValue={initial.companyId || user?.workCompany?.id}
            />
          }
          <FormRow part="1-2">
            <Checkbox name="template" label="Доступен по общей ссылке" withLabelGap/>
            { values.template ?
              <Input
                label="Заголовок"
                name="title"
                errors={errors.title}
                defaultValue={initial.title}/>
              : null
            }
          </FormRow>
          {!values.template && <>
          <Delimeter size="m"/>
          <FormRow>
            <Input
              label="Фамилия"
              name="data.lastName"
              //@ts-ignores
              errors={errors.data?.lastName}
              defaultValue={initial.data?.lastName}
              disabled={values.template}/>
            <Input
              label="Имя"
              name="data.firstName"
              //@ts-ignores
              errors={errors.data?.firstName}
              defaultValue={initial.data?.firstName}
              disabled={values.template}/>
            <Input
              label="Отчество"
              name="data.middleName"
              defaultValue={initial.data?.middleName}
              disabled={values.template}/>
          </FormRow>
          </>}
          <Delimeter size="m"/>
          <FormRow>
            {!values.template &&
            <>
              <Input
                label="Телефон"
                name="data.phone"
                //@ts-ignores
                errors={errors.data?.phone}
                mask="+7 999 999-99-99"
                defaultValue={initial.data?.phone}
                disabled={values.template}/>
              <Input
                label="Email"
                name="data.email"
                //@ts-ignores
                errors={errors.data?.email}
                defaultValue={initial.data?.email}
                disabled={values.template}/>
            </>
            }
            {isAdmin(user) ?
            <SelectCompany<IOffer>
              label="Компания"
              name="companyId"
              errors={errors.companyId}
              defaultValue={initial.companyId}
              onChange={formChangeTrigger}
              entityFrom="offers"
            /> :
            <div/>}
          </FormRow>
          <Delimeter size="m"/>
          <Textarea
            label="Комментарий"
            name="comment"
            placeholder="Информация из данного поля не видна кандидату"
            defaultValue={initial.comment}
          />
          <Delimeter size="m"/>
          <FormRow>
            {/* @ts-ignores */}
            <CheckboxGroup label="Документы для загрузки" errors={errors.data?.documents}>
              { documentsList.filter(doc => !doc.disabled).map(doc => (
                <Checkbox
                  key={doc.docType}
                  name="data.documents[]"
                  value={doc.docType}
                  label={doc.title || ''}
                  defaultChecked={!!initial.data?.documents?.find(document => document === doc.docType)}
                />
              )) }
            </CheckboxGroup>
            {!!sets.length &&
            <CheckboxGroup label="Использовать набор документов">
              { sets.map(docSet => (
                <Checkbox
                  key={docSet.id}
                  name="sets[]"
                  value={docSet.id}
                  label={docSet.title}
                  note={docSet.mappedDocs?.join(', ')}
                  onChange={handleDocsSetChange}
                />
              )) }
            </CheckboxGroup>
            }
          </FormRow>
        </>
      )}
    </Form>
  );
};