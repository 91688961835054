import { Companies } from '~/shared/components/companies/list';
import { Documents } from '~/shared/components/documents';
import { DocumentsSets } from '~/shared/components/documents-set/list';
import { Offers } from '~/shared/components/offers/list';
import { Reports } from '~/shared/components/reports/list';
import { Users } from '~/shared/components/users/list';

import { IEntityList } from './d';

const List:React.FC<IEntityList> = params => (
  <>
    {params.entity === 'users' && <Users {...params}/>}
    {params.entity === 'companies' && <Companies {...params}/>}
    {params.entity === 'offers' && <Offers {...params}/>}
    {params.entity === 'documents' && <Documents {...params}/>}
    {params.entity === 'documents-set' && <DocumentsSets {...params}/>}
    {params.entity === 'reports' && <Reports {...params}/>}
  </>
);

export default List;