export const sortingTypesMap:Record<string, string> = {
  'updatedAt': 'Дата обновления',
  'title': 'Название',
  'email': 'Email',
  'lastName': 'Фамилия',
  'role': 'Роль',
  'workCompany.title': 'Компания',
  'status': 'Статус',
  'data.lastName': 'Фамилия',
  'offerCompany.title': 'Компания'
};