import classNames from 'classnames';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { IValidationError } from '%/validation/d';

import { InputError } from '../error';
import { Label } from '../label';

import styles from './textarea.module.styl';

type ITextareaProps = {
  name: string
  label?: string|React.ReactElement
  errors?: IValidationError
  defaultValue?: string
  placeholder?: string
  onChange?: (event:ChangeEvent<HTMLTextAreaElement>, value:string) => void
  disabled?: boolean
}

export const Textarea:React.FC<ITextareaProps> = ({
  name, label, errors, defaultValue = '', placeholder, onChange, disabled
}) => {
  const clonedRef = useRef<HTMLPreElement>();
  const controlRef = useRef<HTMLTextAreaElement>();
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event:ChangeEvent<HTMLTextAreaElement>) => {
    const cpEvent = event;
    setValue(cpEvent.target.value);
    onChange?.(cpEvent, cpEvent.target.value);
  };

  useEffect(() => { setValue(defaultValue) }, [defaultValue]);

  useEffect(() => {
    if (controlRef.current && clonedRef.current) {
      const { clientHeight } = clonedRef.current;
      controlRef.current.style.height = clientHeight + 24 + 'px';
    }
  }, [value]);

  return (
    <div className={styles.root}>
      { label && <Label>{ label }</Label> }
      <div className={classNames(styles.wrapper, { [styles.error]: errors })}>
        <pre ref={clonedRef} className={styles.cloned} dangerouslySetInnerHTML={{ __html: value }}/>
        <textarea
          ref={controlRef}
          name={name}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          disabled={disabled}
          autoComplete="off"/>
      </div>
      <InputError errors={errors}/>
    </div>
  );
};