import { UserRoles } from '%/data/user-roles';

import { IEntity } from './d';

export const entities:Record<IEntity, string> = {
  'companies': 'Компании',
  'users': 'Пользователи',
  'offers': 'Заявки',
  'documents': 'Документы',
  'documents-set': 'Наборы документов',
  'reports': 'Отчеты'
};
export const entitiesAccusative:Record<IEntity, string> = {
  'companies': 'компанию',
  'users': 'пользователя',
  'offers': 'заявку',
  'documents': 'документ',
  'documents-set': 'набор документов',
  'reports': 'отчет'
};

export const availableEntitiesByRole:Partial<Record<keyof typeof UserRoles, IEntity[]>> = {
  admin: ['companies', 'users', 'documents', 'documents-set', 'offers', 'reports'],
  headhr: ['companies', 'users', 'documents-set', 'offers'],
  hr: ['offers', 'documents-set']
};

export const entitiesSorting:Record<IEntity, string[]> = {
  'companies': ['updatedAt', 'title', 'email'],
  'users': ['updatedAt', 'email', 'lastName', 'role', 'workCompany.title'],
  'offers': ['updatedAt', 'status', 'data.lastName', 'offerCompany.title'],
  'documents': ['updatedAt', 'title'],
  'documents-set': ['updatedAt', 'title'],
  'reports': ['updatedAt', 'title'],
};