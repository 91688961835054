import { createElement } from 'react';

import { UserRoles, UserRolesRu } from '%/data/user-roles';
import { getUserNameInitials } from '~/shared/tools/get-name-initials';

import styles from './table.module.styl';

export const headNamesMap:Record<string, string> = {
  phone: 'Телефон',
  email: 'Почта',
  title: 'Название',
  docTitle: 'Название документа',
  name: 'Имя',
  createdAt: 'Создан',
  updatedAt: 'Обновлен',
  role: 'Роль в системе',
  status: '',
  recognizePreset: 'Пресет в Basis.Docs',
  docType: 'Тип документа',
  disabled: '',
  personal: 'Личный',
  mappedDocs: 'Входящие документы',
  logo: '',
  companyLogo: '',
  companyTitle: 'Компания',
  candidateName: 'ФИО кандидата',
  candidatePhone: 'Телефон',
  comment: 'Комментарий',
  type: 'Тип',
  cron: 'Периодичность',
  personalizable: 'Персонализируемый',
  template: 'Шаблон',
  active: ''
};

const printDate = (val:string) => {
  const d = new Date(val);
  if (!d) return val;
  return `${`0${d.getDate()}`.slice(-2)}.${`0${d.getMonth() + 1}`.slice(-2)}.${d.getFullYear()} ${`0${d.getHours()}`.slice(-2)}:${`0${d.getMinutes()}`.slice(-2)}`;
};

export const transform:Record<string, (value:unknown, item:any) => React.ReactNode> = {
  name: (val, item) => getUserNameInitials(item),
  candidateName: (val, item) => item.template ? item.title : getUserNameInitials(item.data),
  candidatePhone: (val, item) => item.data.phone,
  createdAt: printDate,
  updatedAt: printDate,
  role: (role:UserRoles) => UserRolesRu[role],
  docTitle: (val, item) => item.title,
  companyTitle: (val, item) => item.offerCompany?.title || item.workCompany?.title,
  disabled: (val:boolean) => createElement('span', { className: styles[`status-${val ? 'inactive' : 'active'}`] }),
  personal: (val:boolean) => val ? 'Да' : 'Нет',
  mappedDocs: (val:string[]) => createElement('span', { dangerouslySetInnerHTML: { __html: val.join('<br/>') } }),
  logo: (val:string, item) => (val || item.offerCompany?.logo || item.workCompany?.logo) ?
    createElement('img', { src: val || item.offerCompany?.logo || item.workCompany?.logo }) :
    null,
  status: (val:string) => createElement('span', { className: styles[`status-${val.toLowerCase()}`] }),
  personalizable: (val:boolean) => val ? 'Да' : 'Нет',
  template: (val:boolean) => val ? 'Да' : 'Нет',
  active: (val:boolean) => createElement('span', { className: styles[`status-${val ? 'active' : 'inactive'}`] }),
};