import { IDocumentSet } from '%/entities/documents-set';
import { generatePath, getViewAbsoluteUrl } from '~/core/views';
import { IEntitiesList } from '~/shared/components/entities/d';
import { Tiles } from '~/shared/components/tiles';
import { ITile } from '~/shared/components/tiles/d';

const castItemToTile = (item:IDocumentSet):ITile<IDocumentSet> => ({
  id: item.id,
  title: item.title,
  subtitle: item.personal ? 'Личный' : '',
  description: item.mappedDocs?.join('<br/>'),
  url: `${generatePath(getViewAbsoluteUrl('entities', 'edit'), { entity: 'documents-set', id: item.id })}`,
  urlState: { initial: item }
});

export const DocumentsSets:React.FC<IEntitiesList<IDocumentSet>> = ({ items }) => {
  return <Tiles<IDocumentSet> items={items.map(item => castItemToTile(item))}/>;
};