import { useNavigate } from 'react-router-dom';

import { IError } from '%/entities/error';
import { IOffer } from '%/entities/offer';
import { generatePath, getViewAbsoluteUrl } from '~/core/views';
import { Delimeter } from '~/shared/components/delimeter';
import { OfferCreateForm } from '~/shared/components/offers/create-form';
import { Paragraph } from '~/shared/components/paragraph';

import styles from './entities.module.styl';

const HRGenerateLink:React.FC = () => {
  const navigate = useNavigate();

  const submitHandler = (offer:IOffer|IError) => {
    if (!(offer as IError)?.errorStatus) {
      navigate(generatePath(getViewAbsoluteUrl('entities', 'success'), { entity: 'offers', id: (offer as IOffer).id }), { state: { ...offer } });
    }
  };

  return (
    <div className={styles.form}>
      <h1>Генерация ссылки для кандидата</h1>
      <Paragraph>Укажите данные кандидата, выберите документы, которые ему необходимо загрузить и сгенерируйте ссылку.</Paragraph>
      <Delimeter size="m"/>
      <OfferCreateForm onSubmit={submitHandler}/>
    </div>
  );
};

export default HRGenerateLink;