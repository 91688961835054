import { IValidationError } from '%/validation/d';

import { InputError } from '../error';
import { Label } from '../label';

import styles from './checkbox-group.module.styl';

type ICheckboxGroupProps = {
  label: string
  errors?: IValidationError
  children?: React.ReactNode
}

export const CheckboxGroup:React.FC<ICheckboxGroupProps> = ({ label, errors, children }) => {
  return (
    <div className={styles.root}>
      { label && <Label>{ label }</Label> }
      { children }
      <InputError errors={errors || ''}/>
    </div>
  );
};