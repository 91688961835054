import { useEffect, useState } from 'react';

import { UserRoles, UserRolesRu } from '%/data/user-roles';
import { IError } from '%/entities/error';
import { IReport } from '%/entities/report';
import { IUser } from '%/entities/user';
import { deflatObject } from '%/utils/flat-object';
import { isAnyHr, isEmployer } from '%/utils/user';
import { userValidate } from '%/validation/user';
import { Checkbox } from '~/shared/components/controls/checkbox';
import { CheckboxGroup } from '~/shared/components/controls/checkbox-group';
import { Form } from '~/shared/components/controls/form';
import { IFormRenderProps } from '~/shared/components/controls/form/d';
import { FormRow } from '~/shared/components/controls/form/form-row';
import { Input } from '~/shared/components/controls/input';
import { Select } from '~/shared/components/controls/select';
import { mapHashToOption } from '~/shared/components/controls/select/utils';
import { SelectCompany } from '~/shared/components/controls/select-company';
import { Delimeter } from '~/shared/components/delimeter';
import { IEntityForm } from '~/shared/components/entities/d';
import { useNotification } from '~/shared/components/notification';
import { useReportActions } from '~/shared/hooks/use-report-actions';
import { useUser } from '~/shared/hooks/use-user';
import { useUserActions } from '~/shared/hooks/use-user-actions';

const FORM_ID = 'createUserProcess';

export const UserForm:React.FC<IEntityForm<IUser>> = ({ initial = {} as IUser, onSubmit }) => {
  const { user, updateUser: udateUserLocal } = useUser();
  const { createUser, updateUser } = useUserActions();
  const { addSendingId, removeSendingId } = useNotification();
  const { getReports } = useReportActions();
  const [newUserRole, setNewUserRole] = useState<keyof typeof UserRoles|undefined>(undefined);
  const [reports, setReports] = useState<IReport[]>([]);

  const changeHandler = (values:IUser) => {
    setNewUserRole(values?.role);
  };

  const submitHandler = async (values:IUser) => {
    const action = values?.id ? updateUser : createUser;
    const body:IUser = (Object.keys(values) as (keyof IUser)[])
      .reduce((target:IUser, key:keyof IUser) => {
        target = key.startsWith('__') ? target : {
          ...target,
          [key]: values[key]
        };
        return target;
      }, {} as IUser);
    const reqBody:IUser = {
      ...body,
      companyId: values?.companyId || undefined
    };

    addSendingId(FORM_ID);
    const resp:IError|IUser = await action(reqBody);
    removeSendingId(FORM_ID);

    if ((resp as IError).errors) {
      return deflatObject((resp as IError).errors);
    }
    if (initial?.id === user?.id) {
      udateUserLocal?.(resp as IUser);
    }

    onSubmit?.(resp);
  };

  const loadReports = async () => {
    const resp:IReport[]|IError = await getReports();
    if (!(resp as IError).errorStatus) {
      setReports(resp as IReport[]);
    }
  };

  useEffect(() => {
    loadReports();
  }, []);

  return (
    <Form<IUser>
      onSubmit={submitHandler}
      onChange={changeHandler}
      validate={userValidate}
      submitBtn="Сохранить"
      formId={FORM_ID}
      initial={initial}
      restoreValues={!initial?.id}
    >
      {({ errors, initial, formChangeTrigger }:IFormRenderProps<IUser>) => (
        <>
          {initial?.id && <Input type="hidden" name="id" defaultValue={initial.id}/>}
          <FormRow>
            <Input
              label="Фамилия"
              name="lastName"
              errors={errors.lastName}
              defaultValue={initial.lastName}/>
            <Input
              label="Имя"
              name="firstName"
              errors={errors.firstName}
              defaultValue={initial.firstName}/>
            <Input
              label="Отчество"
              name="middleName"
              defaultValue={initial.middleName}/>
          </FormRow>
          <Delimeter size="m"/>
          <FormRow>
            {initial?.id === user?.id ?
              <Input type="hidden" name="role" defaultValue={initial.role}/> :
              <Select<Record<keyof typeof UserRoles, string>>
                label="Роль"
                name="role"
                errors={errors.role}
                defaultValue={initial.role}
                options={mapHashToOption(UserRolesRu)}
                onChange={formChangeTrigger}/>
            }
            <Input
              label="Телефон"
              name="phone"
              errors={errors.phone}
              mask="+7 999 999-99-99"
              defaultValue={initial.phone}/>
            <Input
              label="Email"
              name="email"
              errors={errors.email}
              defaultValue={initial.email}/>
          </FormRow>
          {isAnyHr({ ...initial, role: newUserRole ? newUserRole : initial.role }) &&
          <>
            {initial?.id === user?.id ?
            <Input type="hidden" name="companyId" defaultValue={initial.companyId}/> :
            <>
              <Delimeter size="m"/>
              <FormRow part="2-1">
                <SelectCompany<Required<IUser>>
                  label="Компания"
                  name="companyId"
                  errors={errors.companyId}
                  defaultValue={initial.companyId}
                  onChange={formChangeTrigger}
                  entityFrom="users"
                />
                <Input
                  label="Пресет в Базис.Документы"
                  name="recognizePreset"
                  errors={errors.recognizePreset}
                  defaultValue={initial.recognizePreset}/>
              </FormRow>
            </>
            }
          </>}
          {initial?.id !== user?.id && !isEmployer({ ...initial, role: newUserRole ? newUserRole : initial.role }) &&
          <>
            <Delimeter size="m"/>
            <FormRow>
              <Input
                label="Пароль"
                name="newPassword"
                type="password"
                errors={errors.newPassword}
                password
                generatePassOn/>
              <Input
                label="Повторите пароль"
                name="retryPassword"
                type="password"
                errors={errors.retryPassword}
                password/>
              <div/>
            </FormRow>
          </>}
          {isAnyHr({ ...initial, role: newUserRole ? newUserRole : initial.role }) && reports.filter(el => el.personalizable).length ?
            <>
              <Delimeter size="m"/>
              <CheckboxGroup label={`Включите необходимые отчеты`}>
                {reports.filter(el => el.personalizable).map(report => (
                  <FormRow key={report.id} align="center">
                    <Checkbox
                      name="reports[]"
                      value={report.name}
                      label={report.title}
                      defaultChecked={
                        !!initial.reports?.includes(report.name) ||
                        !!initial.workCompany?.reports?.includes(report.name)
                      }
                    />
                  </FormRow>
                ))}
              </CheckboxGroup>
            </> : null
          }
        </>
      )}
    </Form>
  );
};