import { useState } from 'react';

import { IDocumentSet } from '%/entities/documents-set';
import { isAdmin } from '%/utils/user';
import { Button } from '~/shared/components/controls/button';
import { Delimeter } from '~/shared/components/delimeter';
import { IEntitiesList } from '~/shared/components/entities/d';
import { Popup } from '~/shared/components/popup';
import { Table } from '~/shared/components/table';
import { useDocumentsSetActions } from '~/shared/hooks/use-documents-set-actions';
import { useUser } from '~/shared/hooks/use-user';

export const DocumentsSets:React.FC<IEntitiesList<IDocumentSet>> = ({ items, reload }) => {
  const { deleteDocumentsSet } = useDocumentsSetActions();
  const { user } = useUser();

  const [idToRemove, setIdToRemove] = useState<string>('');
  const clearIdToRemove = () => { setIdToRemove('') };

  const removeHandler = async () => {
    const resp = await deleteDocumentsSet(idToRemove);

    if (!resp?.errorStatus) {
      clearIdToRemove();
      reload?.();
    }
  };

  const isActionsAvailable = (item:IDocumentSet) => isAdmin(user) || (
    item.creatorId === user?.id ||
    (item.companyId === user?.companyId && !item.personal)
  );

  return (
    <>
      <Table<IDocumentSet>
        data={items}
        meta={['title', 'mappedDocs', 'personal']}
        onDeleteAction={setIdToRemove}
        isActionsAvailable={isActionsAvailable}
      />
      {idToRemove &&
        <Popup onClose={clearIdToRemove}>
          <h2>Вы действительно хотите удалить набор документов {items.find(el => el.id === idToRemove)?.title}</h2>
          <Delimeter size="l"/>
          <Button accent onClick={removeHandler}>Подтвердить</Button>
          <Button clear onClick={clearIdToRemove}>Отмена</Button>
        </Popup>
      }
    </>
  );
};