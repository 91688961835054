import { generatePath, getViewAbsoluteUrl } from '~/core/views';
import { ButtonLink } from '~/shared/components/button-link';
import { useUser } from '~/shared/hooks/use-user';

import { availableEntitiesByRole, entities } from './constants';
import { IEntity, IMenu, IMenuItem } from './d';

import styles from './left-menu.module.styl';

export const LeftMenu:React.FC<IMenu> = ({ items = [], hideRoleEntities = false }) => {
  const { user } = useUser();

  return (
    <div className={styles.menu}>
      <div className={styles.menuContent}>
        {items.map((item:IMenuItem) => (
          <ButtonLink
            key={item.url}
            to={item.url}
            text={item.text}
          />
        ))}
        {!hideRoleEntities && user && availableEntitiesByRole[user.role]?.map((entity:IEntity) => (
          <ButtonLink
            key={entity}
            to={generatePath(getViewAbsoluteUrl('entities', 'list'), { entity })}
            text={entities[entity]}
          />
        ))}
      </div>
    </div>
  );
};