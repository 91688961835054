import { useEffect, useState } from 'react';

import { ICompany } from '%/entities/company';
import { IError } from '%/entities/error';
import { IReport } from '%/entities/report';
import { deflatObject } from '%/utils/flat-object';
import { companyValidation } from '%/validation/company';
import { Checkbox } from '~/shared/components/controls/checkbox';
import { CheckboxGroup } from '~/shared/components/controls/checkbox-group';
import { Form } from '~/shared/components/controls/form';
import { IFormRenderProps } from '~/shared/components/controls/form/d';
import { FormRow } from '~/shared/components/controls/form/form-row';
import { Input } from '~/shared/components/controls/input';
import { InputDnd } from '~/shared/components/controls/input-dnd';
import { Delimeter } from '~/shared/components/delimeter';
import { IEntityForm } from '~/shared/components/entities/d';
import { useNotification } from '~/shared/components/notification';
import { useCompanyActions } from '~/shared/hooks/use-company-actions';
import { useReportActions } from '~/shared/hooks/use-report-actions';
import { IFile } from '~/typings/file';

const FORM_ID = 'createCompanyProcess';
const INITIAL_FILES:IFile[] = [];

export const CompanyForm:React.FC<IEntityForm<ICompany>> = ({
  initial = { recognizePreset: 'rusagro' } as ICompany, onSubmit
}) => {
  const { createCompany, updateCompany } = useCompanyActions();
  const { getReports } = useReportActions();
  const { addSendingId, removeSendingId } = useNotification();
  const [reports, setReports] = useState<IReport[]>([]);

  const submitHandler = async (values:ICompany) => {
    const action = values.id ? updateCompany : createCompany;
    const body:ICompany = (Object.keys(values) as (keyof ICompany)[])
      .reduce((target:ICompany, key:keyof ICompany) => {
        target = key.startsWith('__') ? target : {
          ...target,
          [key]: values[key]
        };
        return target;
      }, {} as ICompany);
    const reqBody:Partial<ICompany> = {
      ...body,
      logo: (values.logo as IFile[])[0]?.preview || ''
    };
    addSendingId(FORM_ID);
    const resp:IError|ICompany = await action(reqBody, false);
    removeSendingId(FORM_ID);

    if ((resp as IError).errors) {
      return deflatObject((resp as IError).errors);
    }

    onSubmit?.(resp);
  };

  const loadReports = async () => {
    const resp:IReport[]|IError = await getReports();
    if (!(resp as IError).errorStatus) {
      setReports(resp as IReport[]);
    }
  };

  useEffect(() => {
    loadReports();
  }, []);

  return (
    <Form<ICompany>
      onSubmit={submitHandler}
      validate={companyValidation}
      submitBtn="Сохранить"
      formId={FORM_ID}
      initial={initial}
      restoreValues={!initial.id}
    >
      { ({ errors, initial, filesRef }:IFormRenderProps<ICompany>) => (
        <>
          { initial.id &&
            <Input type="hidden" name="id" defaultValue={initial.id}/>
          }
          <InputDnd
            name="logo"
            filesRef={filesRef}
            multiple={false}
            view="button"
            uploadUrl={`/api/files/logo`}
            buttonProps={{
              accent: true,
              bordered: true,
              text: 'Загрузить лого',
              size: 'xs'
            }}
            initialFiles={initial.logo as IFile[] || INITIAL_FILES}
          />
          <Delimeter size="m"/>
          <FormRow>
            <Input
              label="Название компании"
              name="title"
              errors={errors.title}
              defaultValue={initial.title}/>
          </FormRow>
          <Delimeter size="m"/>
          <FormRow>
            <Input
              label="Почта"
              name="email"
              errors={errors.email}
              defaultValue={initial.email}/>
            <Input
              label="Телефон"
              name="phone"
              errors={errors.phone}
              mask="+7 999 999-99-99"
              defaultValue={initial.phone}/>
            <Input
              label="Пресет в Базис.Документы"
              name="recognizePreset"
              errors={errors.recognizePreset}
              defaultValue={initial.recognizePreset || ''}/>
          </FormRow>
          {reports.length ?
            <>
              <Delimeter size="m"/>
              <CheckboxGroup label={`Включите необходимые отчеты`}>
                {reports.map(report => (
                  <FormRow key={report.id} align="center">
                    <Checkbox
                      name="reports[]"
                      value={report.name}
                      label={report.title}
                      defaultChecked={!!initial.reports?.includes(report.name)}
                    />
                  </FormRow>
                ))}
              </CheckboxGroup>
            </> : null
          }
        </>
      )}
    </Form>
  );
};