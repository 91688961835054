import { useEffect, useState } from 'react';

import { IReport } from '%/entities/report';
import { IEntityList } from '~/shared/components/entities/d';
import { useDictionary } from '~/shared/hooks/use-dictionary';
import { sortData } from '~/shared/tools/sorting';

import { Reports as ReportsTable } from './table';
import { Reports as ReportsTiles } from './tiles';

export const Reports = ({ view, sortingOrder, sortingType, filter }:IEntityList) => {
  const { reportsList, reloadReportsList } = useDictionary();
  const [data, setData] = useState<IReport[]>(reportsList);

  useEffect(() => {
    const filtered = filter ? reportsList.filter(el => el.title.toLowerCase().includes(filter)) : [...reportsList];
    const sorted = sortData<IReport>({ type: sortingType, order: sortingOrder, data: filtered });
    setData([...sorted]);
  }, [sortingOrder, sortingType, filter, reportsList]);

  useEffect(() => {
    reloadReportsList();
  }, []);

  return view === 'table' ?
    <ReportsTable items={data} reload={reloadReportsList}/> :
    <ReportsTiles items={data}/>;
};