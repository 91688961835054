import { useCallback, useEffect, useState } from 'react';

import { IOffer } from '%/entities/offer';
import { initEventSourceApi } from '~/core/api-sse';
import { IEntityList } from '~/shared/components/entities/d';
import { useOfferActions } from '~/shared/hooks/use-offer-actions';
import { useUser } from '~/shared/hooks/use-user';
import { sortData } from '~/shared/tools/sorting';

import { Offers as OffersTable } from './table';
import { Offers as OffersTiles } from './tiles';

export const Offers = ({ view, sortingOrder, sortingType, filter }:IEntityList) => {
  const { getOffers } = useOfferActions();
  const [initial, setInitialOffers] = useState<IOffer[]>([]);
  const [data, setData] = useState<IOffer[]>([]);

  const { user } = useUser();

  const init = useCallback(async () => {
    const data = await getOffers();

    if (!data.errorStatus) {
      setInitialOffers([...data]);
      setData(data);
    }
  }, []);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const filtered = filter ? initial.filter(el =>
      el.data?.email?.toLowerCase().includes(filter) ||
      el.data?.lastName?.toLowerCase().includes(filter) ||
      el.data?.phone?.toLowerCase().includes(filter) ||
      el.data?.phone?.toLowerCase().replace(/[^\d]/g, '').includes(filter)
    ) : [...initial];

    const sorted = sortData<IOffer>({ type: sortingType, order: sortingOrder, data: filtered });
    setData([...sorted]);
  }, [sortingType, sortingOrder, filter]);

  useEffect(() => {
    const sseApi = initEventSourceApi(user?.id || '');
    const sseEventHandler = (event: MessageEvent) => {
      if (event instanceof MessageEvent) {
        const data = JSON.parse(event?.data);
        if (data?.offer?.id) {
          setData(prev => prev.map(o => o.id === data.offer.id ? data.offer : o));
        }
      }
    };
    sseApi.addEventListener('offerupdated', sseEventHandler);
    return () => {
      sseApi.removeEventListener('offerupdated', sseEventHandler);
      sseApi?.close();
    };
  }, []);

  return view === 'table' ?
    <OffersTable items={data}/> :
    <OffersTiles items={data}/>;
};