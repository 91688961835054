import { IReport } from '%/entities/report';
import { generatePath, getViewAbsoluteUrl } from '~/core/views';
import { IEntitiesList } from '~/shared/components/entities/d';
import { Tiles } from '~/shared/components/tiles';
import { ITile } from '~/shared/components/tiles/d';

const castItemToTile = (item:IReport):ITile<IReport> => ({
  id: item.id,
  title: item.title,
  description: item.cron,
  url: `${generatePath(getViewAbsoluteUrl('entities', 'edit'), { entity: 'reports', id: item.id })}`,
  urlState: { initial: item }
});

export const Reports:React.FC<IEntitiesList<IReport>> = ({ items }) => {
  return <Tiles<IReport> items={items.map(item => castItemToTile(item))}/>;
};