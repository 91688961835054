import cn from 'classnames';
import { SyntheticEvent, useState } from 'react';

import { sortingTypesMap } from './constants';
import { ISortOrder, ISortSelect } from './d';

import descSortIcon from '@css-theme/icons/desc-sort.svg';
import sortIcon from '@css-theme/icons/sorting.svg';

import styles from './sort-select.module.styl';

export const SortSelect:React.FC<ISortSelect> = ({ onSet, onReset, types }) => {
  const [sortType, setSortType] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc' as ISortOrder);

  const handleSetClick = (e:SyntheticEvent<HTMLDivElement>) => {
    const type = e.currentTarget.dataset?.type;
    const order = type === sortType && sortOrder === 'asc' ? 'desc' : 'asc';

    setSortType(type);
    setSortOrder(order);

    onSet({ type, order });
  };

  const handleResetClick = () => {
    onReset();
    setSortType(null);
    setSortOrder(null);
  };

  return (
    <div className={styles.root}>
      <span className={styles.icon}>
        <img src={sortIcon}/>
      </span>
      <div className={styles.menu}>
        { types.map(el => (
          <div className={cn(
            styles.item,
            el === sortType ? styles.selected : '',
            styles[sortOrder]
          )}
          key={el}
          data-type={el}
          onClick={handleSetClick}>{ sortingTypesMap[el] || el }<span><img src={descSortIcon}/></span></div>
        ))}
        <div className={styles.item} onClick={handleResetClick}>Сбросить<span/></div>
      </div>
    </div>
  );
};