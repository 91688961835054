import { FocusEvent, useState } from 'react';

import { IOffer } from '%/entities/offer';
import { IUser } from '%/entities/user';
import { isAdmin } from '%/utils/user';
import { Button } from '~/shared/components/controls/button';
import { Input } from '~/shared/components/controls/input';
import { Delimeter } from '~/shared/components/delimeter';
import { useNotification } from '~/shared/components/notification';
import { Popup } from '~/shared/components/popup';
import { useOfferActions } from '~/shared/hooks/use-offer-actions';

import linkIcon from '@css-theme/icons/link.svg';
import recognitionIcon from '@css-theme/icons/recognition.svg';

type IControls = {
  offer: IOffer
  user: IUser
}

export const Controls:React.FC<IControls> = ({ offer, user }) => {
  const { addNotification } = useNotification();
  const [link, setLink] = useState<string | null>(null);
  const [slOpen, slOpenPopup] = useState(false);
  const closePopups = () => {
    slOpenPopup(false);
  };

  const copyLink = () => {
    navigator.clipboard.writeText(link as string);
    addNotification({
      text: 'Ссылка скопирована',
      type: 'success',
      autohide: true,
      duration: 5
    });
    closePopups();
  };
  const highlight = (e:FocusEvent<HTMLInputElement>) => {
    e.target.setSelectionRange(0, -1);
  };

  const { getOfferShortlink, getOfferRecognitionTaskLink } = useOfferActions();
  const getShortLink = async () => {
    const resp = await getOfferShortlink(offer.id);
    setLink(resp?.url);
    slOpenPopup(!!resp?.url);
  };

  const getRecognitionTaskLink = async () => {
    const resp = await getOfferRecognitionTaskLink(offer.id);

    if (resp?.url) {
      window.open(resp.url, '_blank');
    }
  };

  return (<>
    <span onClick={getShortLink}><img src={linkIcon} alt="short link" title="short link"/></span>
    {isAdmin(user) && offer.data?.recognitionTaskId &&
      <span onClick={getRecognitionTaskLink}>
        <img src={recognitionIcon} alt="recognition link" title="recognition link"/>
      </span>
    }
    {slOpen &&
      <Popup onClose={closePopups}>
        <Input readonly onClick={highlight} name="link" defaultValue={link || ''} focused/>
        <Delimeter/>
        <Button text="Скопировать" onClick={copyLink} accent size="l"/>
      </Popup>
    }
  </>);
};