import { useState } from 'react';

import { IReport } from '%/entities/report';
import { Button } from '~/shared/components/controls/button';
import { Delimeter } from '~/shared/components/delimeter';
import { IEntitiesList } from '~/shared/components/entities/d';
import { Popup } from '~/shared/components/popup';
import { Table } from '~/shared/components/table';
import { useReportActions } from '~/shared/hooks/use-report-actions';

export const Reports:React.FC<IEntitiesList<IReport>> = ({ items, reload }) => {
  const { deleteReport } = useReportActions();
  const [loading, setLoading] = useState(false);

  const [idToRemove, setIdToRemove] = useState(null);
  const clearIdToRemove = () => { setIdToRemove(null) };

  const removeHandler = async () => {
    setLoading(true);
    const resp = await deleteReport(idToRemove);

    if (!resp?.errorStatus) {
      clearIdToRemove();
      reload();
    }
    setLoading(false);
  };

  return (
    <>
      <Table<IReport>
        data={items}
        meta={['title', 'cron', 'personalizable', 'active']}
      />
      {idToRemove &&
        <Popup onClose={clearIdToRemove}>
          <h2>Вы действительно хотите удалить компанию «{items.find(el => el.id === idToRemove).title}»</h2>
          <Delimeter size="l"/>
          <Button accent loading={loading} onClick={removeHandler}>Подтвердить</Button>
          <Button clear onClick={clearIdToRemove}>Отмена</Button>
        </Popup>
      }
    </>
  );
};