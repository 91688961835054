import { useState } from 'react';

import { IDocument } from '%/entities/document';
import { Button } from '~/shared/components/controls/button';
import { Delimeter } from '~/shared/components/delimeter';
import { IEntitiesList } from '~/shared/components/entities/d';
import { Popup } from '~/shared/components/popup';
import { Table } from '~/shared/components/table';
import { useDocumentsActions } from '~/shared/hooks/use-documents-actions';

export const Documents:React.FC<IEntitiesList<IDocument>> = ({ items, reload }) => {
  const { deleteDocument } = useDocumentsActions();

  const [idToRemove, setIdToRemove] = useState<string|null>(null);
  const [loading, setLoading] = useState<boolean|undefined>(undefined);
  const clearIdToRemove = () => { setIdToRemove(null) };

  const removeHandler = async () => {
    setLoading(true);
    const resp = await deleteDocument?.(idToRemove as string);

    if (!resp?.errorStatus) {
      clearIdToRemove();
      reload?.();
    }
    setLoading(false);
  };

  return (
    <>
      <Table<IDocument>
        data={items}
        meta={['docTitle', 'docType', 'disabled']}
        onDeleteAction={setIdToRemove}
      />
      {idToRemove &&
        <Popup onClose={clearIdToRemove}>
          <h2>Вы действительно хотите удалить документ {items.find(el => el.id === idToRemove)?.title}</h2>
          <Delimeter size="l"/>
          <Button accent loading={loading} onClick={removeHandler}>Подтвердить</Button>
          <Button clear onClick={clearIdToRemove}>Отмена</Button>
        </Popup>
      }
    </>
  );
};