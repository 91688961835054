import cn from 'classnames';
import { useDeferredValue, useState } from 'react';
import { Navigate, useMatch, useNavigate, useRoutes } from 'react-router-dom';

import { isAnyHr } from '%/utils/user';
import { generatePath, getViewAbsoluteUrl, views } from '~/core/views';
import { ButtonLink } from '~/shared/components/button-link';
import { Delimeter } from '~/shared/components/delimeter';
import { Entities, EntityDetail } from '~/shared/components/entities';
import { availableEntitiesByRole, entitiesSorting } from '~/shared/components/entities/constants';
import { IEntitiesView, IEntity } from '~/shared/components/entities/d';
import { LeftMenu } from '~/shared/components/entities/left-menu';
import { FilterInput } from '~/shared/components/filter-input';
import { PageLayout } from '~/shared/components/page-layout';
import { SortSelect } from '~/shared/components/sort-select';
import { ISortOrder, ISortParams } from '~/shared/components/sort-select/d';
import { useUser } from '~/shared/hooks/use-user';

import { HRGenerateLink, HRGenerateLinkComplete } from './';

import tableIcon from '@css-theme/icons/tableview.svg';
import tileIcon from '@css-theme/icons/tileview.svg';

import styles from './entities.module.styl';

const EntitiesView:React.FC = () => {
  const navigate = useNavigate();
  const isRootPage = useMatch(getViewAbsoluteUrl('entities', 'list'));
  const isAddPage = useMatch(getViewAbsoluteUrl('entities', 'add'));
  const isEditPage = useMatch(getViewAbsoluteUrl('entities', 'edit'));
  let { entity, id } = {
    ...isRootPage?.params,
    ...isAddPage?.params,
    ...isEditPage?.params
  } as { entity:IEntity, id:string };
  entity = entity || 'offers';

  const { user } = useUser();

  const isKnowedEntity = user ? availableEntitiesByRole[user.role]?.includes(entity) : false;
  const goBack = () => { navigate(generatePath(getViewAbsoluteUrl('entities', 'list'), { entity })) };

  const [view, setView] = useState('table' as IEntitiesView);
  const setTableView = () => setView('table');
  const setTileView = () => setView('tile');

  const [sType, setSortingType] = useState<string|undefined>(undefined);
  const [sOrder, setSortingOrder] = useState<ISortOrder|undefined>(undefined);
  const setSorting = ({ type, order }:ISortParams) => {
    setSortingType(type);
    setSortingOrder(order);
  };
  const resetSorting = () => {
    setSortingType(undefined);
    setSortingOrder(undefined);
  };

  const [filter, setFilter] = useState<string|undefined>(undefined);
  const filterQuery = useDeferredValue(filter);
  const handleFilterChange = (val:string) => {
    setFilter(val);
  };

  const routes = useRoutes([
    { path: views.entities.list, element: <Entities
      entity={entity}
      view={view}
      sortingType={sType}
      sortingOrder={sOrder}
      filter={filterQuery}
    /> },
    { path: views.entities.add, element: <EntityDetail
      entity={entity}
      renderComponent={isAnyHr(user) && entity === 'offers' ? (props:any) => <HRGenerateLink {...props}/> : null}
    /> },
    { path: views.entities.edit, element: <EntityDetail entity={entity} id={id}/> },
    { path: views.entities.success, element: <HRGenerateLinkComplete/> },
    { path: '*', element: <Navigate to={generatePath(getViewAbsoluteUrl('entities', 'list'), { entity: 'offers' })} replace/> }
  ]);

  return (
    <PageLayout>
      <LeftMenu/>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          {!isRootPage ?
          <ButtonLink
            onClick={goBack}
            size="s"
            accent
            clear
          >&#8592;&nbsp;К списку</ButtonLink> :
          <div/>}
          {isKnowedEntity && !isAddPage && !isEditPage &&
          <div className={styles.controls}>
            <ButtonLink
              to={generatePath(getViewAbsoluteUrl('entities', 'add'), { entity })}
              end
            >+ Добавить</ButtonLink>
            <span className={cn(styles.viewButton, view === 'table' ? styles.selected : '')} onClick={setTableView}>
              <span className={styles.icon}><img src={tableIcon}/></span>
            </span>
            <span className={cn(styles.viewButton, view === 'tile' ? styles.selected : '')} onClick={setTileView}>
              <span className={styles.icon}><img src={tileIcon}/></span>
            </span>
            <span className={cn(styles.viewButton, filterQuery ? styles.selected : '')}>
              <FilterInput onInput={handleFilterChange}/>
            </span>
            <span className={cn(styles.viewButton, sType ? styles.selected : '')}>
              <SortSelect onSet={setSorting} onReset={resetSorting} types={entitiesSorting[entity]}/>
            </span>
          </div>}
        </div>
        <Delimeter size="xxs"/>
        <div className={styles.contentBody}>
          { routes }
        </div>
      </div>
    </PageLayout>
  );
};

export default EntitiesView;