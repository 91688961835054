import { useCallback, useEffect, useState } from 'react';

import { ICompany } from '%/entities/company';
import { IEntityList } from '~/shared/components/entities/d';
import { useCompanyActions } from '~/shared/hooks/use-company-actions';
import { sortData } from '~/shared/tools/sorting';

import { Companies as CompaniesTable } from './table';
import { Companies as CompaniesTiles } from './tiles';

export const Companies = ({ view, sortingOrder, sortingType, filter }:IEntityList) => {
  const { getCompanies } = useCompanyActions();
  const [initial, setInitialData] = useState<ICompany[]>([]);
  const [data, setData] = useState<ICompany[]>([]);

  const init = useCallback(async () => {
    const data = await getCompanies();

    if (!data.errorStatus) {
      setInitialData([...data]);
      setData(data);
    }
  }, []);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const filtered = filter ? initial.filter(el =>
      el.email?.toLowerCase().includes(filter) ||
      el.title.toLowerCase().includes(filter) ||
      el.phone?.toLowerCase().includes(filter) ||
      el.phone?.toLowerCase().replace(/[^\d]/g, '').includes(filter)
    ) : [...initial];

    const sorted = sortData<ICompany>({ type: sortingType, order: sortingOrder, data: filtered });
    setData([...sorted]);
  }, [sortingOrder, sortingType, filter]);

  return view === 'table' ?
    <CompaniesTable items={data} reload={init}/> :
    <CompaniesTiles items={data}/>;
};