import { FocusEvent } from 'react';
import { useLocation } from 'react-router-dom';

import { generatePath, getViewAbsoluteUrl } from '~/core/views';
import { Button } from '~/shared/components/controls/button';
import { Input } from '~/shared/components/controls/input';
import { useNotification } from '~/shared/components/notification';
import { SuccessLayout } from '~/shared/components/success-layout';
import { useUser } from '~/shared/hooks/use-user';

import styles from './entities.module.styl';

const backText = 'Сгенерировать другую ссылку';

const HRGenerateLinkComplete:React.FC = () => {
  const location = useLocation();
  const { addNotification } = useNotification();
  const { url, data } = location.state;
  const { user } = useUser();

  const copy = () => {
    navigator.clipboard.writeText(url);
    addNotification({
      text: 'Ссылка скопирована',
      type: 'success',
      autohide: true,
      duration: 5
    });
  };

  const highlight = (e:FocusEvent<HTMLInputElement>) => {
    e.target.setSelectionRange(0, -1);
  };

  return (
    <SuccessLayout
      title="Передайте ссылку кандидату"
      text={`Когда ${data?.firstName} загрузит документы, они будут проверены и отправлены вам на ${user?.email || user?.workCompany?.email}.`}
      buttons={url ?
        <div className={styles.buttons}>
          <Input readonly onClick={highlight} name="link" defaultValue={url} focused/>
          <Button text="Скопировать" onClick={copy} accent size="l"/>
        </div> : null
      }
      backText={backText}
      backUrl={generatePath(getViewAbsoluteUrl('entities', 'add'), { entity: 'offers' })}
      clear
    />
  );
};

export default HRGenerateLinkComplete;