import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { headNamesMap, transform } from './helper';

import editIcon from '@css-theme/icons/edit.svg';
import deleteIcon from '@css-theme/icons/trash.svg';

import styles from './table.module.styl';

type ITable<T> = {
  data: T[]
  meta: string[]
  onDeleteAction?: (id:string) => void
  isActionsAvailable?: (item:T) => boolean
  renderActions?: (item:T) => React.ReactNode
}

export const Table = <T extends Record<string, unknown>>({
  data, meta, onDeleteAction, isActionsAvailable = () => true, renderActions
}:ITable<T>) => {
  meta = [...meta, 'createdAt'];

  return (
    <div className={styles.root}>
      <div className={styles.rowHead}>
        {meta.map(key => (
          <div className={classNames(styles.cellHead, styles[`cell-${key}`])} key={key}>
            { headNamesMap[key] || '' }
          </div>
        ))}
        <div className={classNames(styles.cell, styles[`cell-actions`])}/>
      </div>
      {data.map(item => (
        <div className={styles.row} key={`${item.id}`}>
          {meta.map(key => (
            <span className={classNames(styles.cell, styles[`cell-${key}`])} key={key}>
              {/* @ts-ignore */}
              { transform[key]?.(item[key], item) || item[key] }
            </span>
          ))}
          {isActionsAvailable?.(item) &&
            <span className={classNames(styles.cell, styles[`cell-actions`])}>
              {renderActions?.(item)}
              <NavLink to={`edit/${item.id}`} state={{ initial: item }}><img src={editIcon}/></NavLink>
              {onDeleteAction &&
                <span onClick={() => onDeleteAction(item.id as string)}><img src={deleteIcon}/></span>
              }
            </span>
          }
        </div>
      ))}
    </div>
  );
};