import classNames from 'classnames';
import { ChangeEvent, KeyboardEvent, MutableRefObject, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';

import { IError } from '%/entities/error';
import { useMounted } from '~/shared/hooks/use-mounted';
import { delay } from '~/shared/tools/delay';

import { InputError } from '../error';
import { Label } from '../label';
import { IActionOption, IOption, ISelectProps } from './d';
import { getValue } from './utils';

import styles from './select.module.styl';

export const Select = <T, >({
  name, label, errors, defaultValue = '', setDefaultValue, placeholder,
  onChange, options: initial = [], multiple = false,
  loadOptions, mapOption, actionOptions = [],
  clearable = true, disabled
}:ISelectProps<T>) => {
  const optionsRef:MutableRefObject<HTMLDivElement|null> = useRef(null);
  const optionsWrapperRef:MutableRefObject<HTMLDivElement|null> = useRef(null);
  const isMounted = useMounted();

  const [error, setError] = useState(errors);
  const [value, setValue] = useState(getValue(defaultValue, multiple));
  const [options, setOptions] = useState(mapOption ?
    [...(initial as T[]).map(mapOption), ...actionOptions] :
    [...(initial as IOption[]), ...actionOptions]);

  const [focused, setFocused] = useState<number|null>(null);
  const [focusMultiple, setFocusMultiple] = useState<number|null>(null);

  const [opened, setOpened] = useState(false);
  const openOptions = useCallback(() => {
    setOpened(true);
    setFocused(0);
  }, []);
  const closeOptions = useCallback(() => {
    if (!isMounted()) return;
    setOpened(false);
    setFocusMultiple(null);
  }, []);
  const getOptions = useCallback(async () => {
    if (loadOptions) {
      const resp = await loadOptions();
      if (!(resp as IError)?.errorStatus) {
        mapOption && setOptions([...(resp as T[]).map(mapOption), ...actionOptions]);
        if (setDefaultValue) {
          setValue(getValue(setDefaultValue(resp as T[]), multiple));
        }
      }
    }
  }, [loadOptions]);

  const handleKeyDown = useCallback((event:KeyboardEvent<HTMLInputElement>) => {
    const key = event.keyCode;
    // left handle tab press for change focused input on page
    if (key !== 9) {
      event.preventDefault();
      // enter or arrow down open option list, lost focus for multiple values
      if (!opened && (key === 13 || key === 40)) {
        setFocusMultiple(0);
        openOptions();
      }
      // arrow up focus multiple selected values
      if (multiple && !opened && key === 38 && value?.length) setFocusMultiple(0);

      // escape close option list
      if (opened && key === 27) closeOptions();
      // down arrow for set focused option
      if (opened && key === 40) setFocused(((focused || 0) + 1) % options.length);
      // up arrow for set focused option
      if (opened && key === 38) setFocused(((focused || 0) + options.length - 1) % options.length);
      // right arrow or enter for select option
      if (opened && typeof focused === 'number' && (key === 39 || key === 13)) {
        (options[focused] as IActionOption).onClick ?
          (options[focused] as IActionOption).onClick?.() :
          handleChange({ target: { value: (options[focused] as IOption).value } } as ChangeEvent<HTMLInputElement>);
        closeOptions();
      }

      // right arrow for set focused multiple value
      if (multiple && typeof focusMultiple === 'number' && key === 39) {
        setFocusMultiple((focusMultiple + 1) % value.length);
      }
      // left arrow for set focused multiple value
      if (multiple && typeof focusMultiple === 'number' && key === 37) {
        setFocusMultiple((focusMultiple + value.length - 1) % value.length);
      }
      // backspace for delete focused multiple value
      if (typeof focusMultiple === 'number' && key === 8) {
        handleChange({ target: { value: value[focusMultiple] } } as ChangeEvent<HTMLInputElement>);
        setFocusMultiple(null);
      }
    }
  }, [opened, focused, options, multiple, focusMultiple]);

  const handleFocus = useCallback(() => {
    openOptions();
  }, []);
  const handleBlur = useCallback(async () => {
    await delay(150);
    closeOptions();
  }, []);

  const handleChange = useCallback(async (event:ChangeEvent<HTMLInputElement>) => {
    const cpEvent = event;
    const selected = event.target.value || '';
    const newValue = multiple ?
      value.includes(selected) ? (value as string[]).filter(el => el !== selected) : value.concat(selected) :
      selected;

    setError('');
    setValue(newValue);
    await delay(1);
    onChange?.(cpEvent, newValue);
  }, [multiple, value, onChange]);

  const handleSelect = useCallback((event:SyntheticEvent<HTMLDivElement>) => {
    const selected = event.currentTarget.dataset.value;
    handleChange({ target: { value: selected } } as ChangeEvent<HTMLInputElement>);
  }, [handleChange]);

  const handleClear = useCallback(() => {
    handleChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
  }, []);

  useEffect(() => {
    if (typeof focused === 'number') {
      const focusedOption = optionsRef.current?.children[focused] as HTMLElement;
      optionsWrapperRef.current?.scrollTo(0,
        Math.max(0, focusedOption.offsetTop + focusedOption.clientHeight - optionsWrapperRef.current.clientHeight));
    }
  }, [focused]);

  useEffect(() => { setValue(getValue(defaultValue, multiple)) }, [defaultValue]);
  useEffect(() => { getOptions() }, [loadOptions]);
  useEffect(() => { setError(errors) }, [errors]);

  return options?.length ? (
    <div className={styles.root}>
      { label && <Label>{ label }</Label> }
      <div className={classNames(styles.wrapper, { [styles.error]: error, [styles.disabled]: disabled })}>
        <div className={classNames(styles.select, {
          [styles.multiple]: multiple,
          [styles.opened]: opened,
          [styles.filled]: value?.length
        })}>
          { !multiple && value &&
            <div className={styles.value}>
              { options.find((option:IOption) => option.value === value)?.text }
            </div>
          }
          <input
            type="text"
            name={name}
            value={value || ''}
            disabled={disabled}
            placeholder={placeholder}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}/>
          { multiple && value?.length &&
            <div className={styles.values}>
              { (value as string[]).map((val, i) => (
                <div
                  key={val}
                  data-value={val}
                  className={classNames(styles.valuesItem, { [styles.focused]: focusMultiple === i })}
                  onClick={handleSelect}
                >{ options.find((option:IOption) => option.value === val)?.text }</div>
              )) }
            </div>
          }
          {clearable && !!value?.length && <span className={styles.clearBtn} onClick={handleClear}/>}
        </div>
        { opened &&
          <div className={classNames(styles.options, { [styles.scrollable]: options?.length > 5 })}>
            <div className={styles.optionsScrollable} ref={optionsWrapperRef}>
              <div className={styles.optionsList} ref={optionsRef}>
                { options.map((option:IOption|IActionOption, i) =>
                  <div
                    data-value={(option as IOption).value || (option as IActionOption).name}
                    key={(option as IOption).value || (option as IActionOption).name}
                    className={classNames(styles.optionsItem, { [styles.focused]: i === focused })}
                    onClick={(option as IActionOption).onClick || handleSelect}>
                    { option.label && <span className={styles.optionsLabel}>{ option.label }</span> }
                    { option.prefix && <span className={styles.optionsPrefix}>{ option.prefix }</span> }
                    { option.text }
                    { option.suffix && <span className={styles.optionsSuffix}>{ option.suffix }</span> }
                    { option.note && <span className={styles.optionsNote}>{ option.note }</span> }
                  </div>
                )}
              </div>
            </div>
          </div>
        }
      </div>
      <InputError errors={error || ''}/>
    </div>
  ) : null;
};