import { useState } from 'react';

import { IUser } from '%/entities/user';
import { Button } from '~/shared/components/controls/button';
import { Delimeter } from '~/shared/components/delimeter';
import { IEntitiesList } from '~/shared/components/entities/d';
import { Popup } from '~/shared/components/popup';
import { Table } from '~/shared/components/table';
import { useUser } from '~/shared/hooks/use-user';
import { useUserActions } from '~/shared/hooks/use-user-actions';

export const Users:React.FC<IEntitiesList<IUser>> = ({ items, reload }) => {
  const { user } = useUser();
  const { id: currentId } = user || {};
  const { deleteUser } = useUserActions();

  const [loading, setLoading] = useState(false);
  const [idToRemove, setIdToRemove] = useState<string>('');
  const clearIdToRemove = () => { setIdToRemove('') };

  const removeHandler = async () => {
    setLoading(true);
    const resp = await deleteUser(idToRemove);

    if (!resp?.errorStatus) {
      clearIdToRemove();
      reload?.();
    }
    setLoading(false);
  };
  return (
    <>
      <Table<IUser>
        data={items}
        meta={['logo', 'name', 'role', 'phone', 'email']}
      />
      {idToRemove && idToRemove !== currentId &&
        <Popup onClose={clearIdToRemove}>
          <h2>Вы действительно хотите удалить пользователя {items.find(el => el.id === idToRemove)?.email}</h2>
          <Delimeter size="l"/>
          <Button accent loading={loading} onClick={removeHandler}>Подтвердить</Button>
          <Button clear onClick={clearIdToRemove}>Отмена</Button>
        </Popup>
      }
      {idToRemove && idToRemove === currentId &&
        <Popup onClose={clearIdToRemove}>
          <h2>Вы не можете удалить себя</h2>
          <Delimeter size="l"/>
          <Button accent onClick={clearIdToRemove}>Согласен</Button>
        </Popup>
      }
    </>
  );
};