import { useLocation, useNavigate } from 'react-router-dom';

import { IError } from '%/entities/error';
import { generatePath, getViewAbsoluteUrl } from '~/core/views';
import { CompanyForm } from '~/shared/components/companies/form';
import { DocumentForm } from '~/shared/components/documents/form';
import { DocumentsSetForm } from '~/shared/components/documents-set/form';
import { entitiesAccusative } from '~/shared/components/entities/constants';
import { IEntityDetail } from '~/shared/components/entities/d';
import { OfferCreateForm } from '~/shared/components/offers/create-form';
import { OfferEditForm } from '~/shared/components/offers/edit-form';
import { ReportForm } from '~/shared/components/reports/form';
import { UserForm } from '~/shared/components/users/form';
import { createNavigationParams } from '~/shared/tools/create-navigation-params';

const Detail = ({ entity, renderComponent }:IEntityDetail) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { navigation } = state || {};

  const handleSubmit = <T, >(resp:IError|T) => {
    if (!(resp as IError)?.errorStatus) {
      const { backUrl, nav } = createNavigationParams<T>(navigation, resp as T);
      navigate(
        backUrl || generatePath(getViewAbsoluteUrl('entities', 'list'), { entity }),
        { state: { navigation: nav } }
      );
    }
  };

  const formProps = {
    initial: state?.initial?.id ? {
      ...state.initial,
      ...(typeof state.initial.logo === 'string' ?
        {
          logo: [{
            name: state.initial.title || state.initial.name,
            preview: state.initial.logo
          }]
        } :
        {}
      )
    } : {},
    onSubmit: handleSubmit
  };

  return renderComponent ? (
    renderComponent(formProps)
  ) : (<>
    <h2>{state?.initial?.id ? 'Редактировать' : 'Добавить'} {entitiesAccusative[entity]}</h2>
    {entity === 'companies' && <CompanyForm {...formProps}/>}
    {entity === 'documents' && <DocumentForm {...formProps}/>}
    {entity === 'offers' && state?.initial?.id && <OfferEditForm {...formProps}/>}
    {entity === 'offers' && !state?.initial?.id && <OfferCreateForm {...formProps}/>}
    {entity === 'users' && <UserForm {...formProps}/>}
    {entity === 'documents-set' && <DocumentsSetForm {...formProps}/>}
    {entity === 'reports' && <ReportForm {...formProps}/>}
  </>);
};

export default Detail;