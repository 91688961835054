import { IOffer } from '%/entities/offer';
import { IUser } from '%/entities/user';
import { isAdmin } from '%/utils/user';
import { IEntitiesList } from '~/shared/components/entities/d';
import { Table } from '~/shared/components/table';
import { useUser } from '~/shared/hooks/use-user';

import { Controls } from './controls';

export const Offers:React.FC<IEntitiesList<IOffer>> = ({ items }) => {
  const { user } = useUser();

  const isActionsAvailable = (item:IOffer) => isAdmin(user) || (
    item.creatorId === user?.id ||
    (item.companyId === user?.companyId)
  );
  const renderActions = (item:IOffer) => <Controls offer={item} user={user as IUser}/>;

  return (
    <>
      <Table<IOffer>
        data={items}
        meta={['logo', 'companyTitle', 'candidateName', 'candidatePhone', 'comment', 'template', 'status']}
        isActionsAvailable={isActionsAvailable}
        renderActions={renderActions}
      />
    </>
  );
};