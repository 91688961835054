import { useState } from 'react';

import { ICompany } from '%/entities/company';
import { Button } from '~/shared/components/controls/button';
import { Delimeter } from '~/shared/components/delimeter';
import { IEntitiesList } from '~/shared/components/entities/d';
import { Popup } from '~/shared/components/popup';
import { Table } from '~/shared/components/table';
import { useCompanyActions } from '~/shared/hooks/use-company-actions';

export const Companies:React.FC<IEntitiesList<ICompany>> = ({ items, reload }) => {
  const { deleteCompany } = useCompanyActions();
  const [loading, setLoading] = useState(false);

  const [idToRemove, setIdToRemove] = useState(null);
  const clearIdToRemove = () => { setIdToRemove(null) };

  const removeHandler = async () => {
    setLoading(true);
    const resp = await deleteCompany(idToRemove);

    if (!resp?.errorStatus) {
      clearIdToRemove();
      reload?.();
    }
    setLoading(false);
  };

  return (
    <>
      <Table<ICompany>
        data={items}
        meta={['logo', 'title', 'phone', 'email', 'recognizePreset']}
      />
      {idToRemove &&
        <Popup onClose={clearIdToRemove}>
          <h2>Вы действительно хотите удалить компанию «{items.find(el => el.id === idToRemove)?.title}»</h2>
          <Delimeter size="l"/>
          <Button accent loading={loading} onClick={removeHandler}>Подтвердить</Button>
          <Button clear onClick={clearIdToRemove}>Отмена</Button>
        </Popup>
      }
    </>
  );
};