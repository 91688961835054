import { IError } from '%/entities/error';
import { IReport } from '%/entities/report';
import { deflatObject } from '%/utils/flat-object';
import { reportValidate } from '%/validation/report';
import { VALID_FORMAT_EXPL } from '%/validation/tools/cron';
import { Checkbox } from '~/shared/components/controls/checkbox';
import { Form } from '~/shared/components/controls/form';
import { IFormRenderProps } from '~/shared/components/controls/form/d';
import { FormRow } from '~/shared/components/controls/form/form-row';
import { Input } from '~/shared/components/controls/input';
import { Delimeter } from '~/shared/components/delimeter';
import { IEntityForm } from '~/shared/components/entities/d';
import { useNotification } from '~/shared/components/notification';
import { useReportActions } from '~/shared/hooks/use-report-actions';

const FORM_ID = 'editReportProcess';

export const ReportForm:React.FC<IEntityForm<IReport>> = ({ initial, onSubmit }) => {
  const { createReport, updateReport } = useReportActions();
  const { addSendingId, removeSendingId } = useNotification();
  const action = initial?.id ? updateReport : createReport;

  const submitHandler = async (values:IReport) => {
    //TODO: add report validation
    addSendingId(FORM_ID);
    const resp:IError|IReport = await action(values, false);
    removeSendingId(FORM_ID);

    if ((resp as IError).errors) {
      return deflatObject((resp as IError).errors);
    }

    onSubmit?.(resp);
  };

  return (
    <Form<IReport>
      onSubmit={submitHandler}
      validate={reportValidate}
      submitBtn="Сохранить"
      formId={FORM_ID}
      initial={initial}
    >
      { ({ errors, initial }:IFormRenderProps<IReport>) => (
        <>
          <Input type="hidden" name="id" defaultValue={initial.id}/>
          <FormRow>
            <Input
              label="Название отчета"
              name="title"
              errors={errors.title}
              defaultValue={initial.title}/>
          </FormRow>
          <Delimeter size="m"/>
          <FormRow>
            <Input
              label="Название отчета в БД"
              name="name"
              errors={errors.name}
              defaultValue={initial.name}/>
            <Input
              label="Периодичность"
              name="cron"
              errors={errors.cron}
              defaultValue={initial.cron}
              hint={`cron-формат ${VALID_FORMAT_EXPL}`}
            />
          </FormRow>
          <Checkbox
            name="active"
            label="Активный"
            defaultChecked={initial.active}
          />
          <Checkbox
            name="personalizable"
            label="Может быть отключен персонально пользователем"
            defaultChecked={initial.personalizable}
          />
        </>
      )}
    </Form>
  );
};