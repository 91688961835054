import cn from 'classnames';
import { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { ITile } from './d';

import styles from './tiles.module.styl';

export const Tile = <T, >({ title, subtitle, description, icon, url, urlState, onClick }:ITile<T>) => {
  const navigate = useNavigate();

  const handleClick = (e:SyntheticEvent<HTMLDivElement>) => {
    const event = e;

    if (url && !url.startsWith('/')) {
      window.location.href = url;
      return;
    }
    if (url) {
      navigate(url, { state: urlState });
      return;
    }

    e.stopPropagation();
    e.preventDefault();
    onClick?.(event);
  };

  return (
    <div className={cn(styles.item, url || onClick ? styles.clickable : '')} onClick={handleClick}>
      {icon && <span className={styles.itemIcon}><img src={icon}/></span>}
      <h5 className={styles.itemTitle}>{ title }</h5>
      {subtitle && <h6 className={styles.itemSubtitle}>{ subtitle }</h6>}
      {description && <p className={styles.itemDescription} dangerouslySetInnerHTML={{ __html: description }}/>}
    </div>
  );
};