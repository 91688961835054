import { useCallback, useEffect, useState } from 'react';

import { IUser } from '%/entities/user';
import { IEntityList } from '~/shared/components/entities/d';
import { useUserActions } from '~/shared/hooks/use-user-actions';
import { sortData } from '~/shared/tools/sorting';

import { Users as UsersTable } from './table';
import { Users as UsersTiles } from './tiles';

export const Users = ({ view, sortingType, sortingOrder, filter }:IEntityList) => {
  const { getUsers } = useUserActions();
  const [initial, setInitialData] = useState<IUser[]>([]);
  const [data, setData] = useState<IUser[]>([]);

  const init = useCallback(async () => {
    const data = await getUsers();

    if (!data.errorStatus) {
      setInitialData([...data]);
      setData(data);
    }
  }, []);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    const filtered = filter ? initial.filter(el =>
      el.email?.toLowerCase().includes(filter) ||
      el.lastName.toLowerCase().includes(filter) ||
      el.phone?.toLowerCase().includes(filter) ||
      el.phone?.toLowerCase().replace(/[^\d]/g, '').includes(filter)
    ) : [...initial];

    const sorted = sortData<IUser>({ type: sortingType, order: sortingOrder, data: filtered });
    setData([...sorted]);
  }, [sortingType, sortingOrder, filter]);

  return view === 'table' ?
    <UsersTable items={data as IUser[]} reload={init}/> :
    <UsersTiles items={data as IUser[]}/>;
};