import { ICompany } from '%/entities/company';
import { IDocumentSet } from '%/entities/documents-set';
import { IError } from '%/entities/error';
import { deflatObject } from '%/utils/flat-object';
import { isAdmin } from '%/utils/user';
import { documentsSetValidate } from '%/validation/documents-set';
import { mapCompaniesToOption } from '~/shared/components/companies/utils';
import { Checkbox } from '~/shared/components/controls/checkbox';
import { CheckboxGroup } from '~/shared/components/controls/checkbox-group';
import { Form } from '~/shared/components/controls/form';
import { IFormRenderProps } from '~/shared/components/controls/form/d';
import { FormRow } from '~/shared/components/controls/form/form-row';
import { Input } from '~/shared/components/controls/input';
import { Select } from '~/shared/components/controls/select';
import { Delimeter } from '~/shared/components/delimeter';
import { IEntityForm } from '~/shared/components/entities/d';
import { useCompanyActions } from '~/shared/hooks/use-company-actions';
import { useDictionary } from '~/shared/hooks/use-dictionary';
import { useDocumentsSetActions } from '~/shared/hooks/use-documents-set-actions';
import { useUser } from '~/shared/hooks/use-user';

export const DocumentsSetForm:React.FC<IEntityForm<IDocumentSet>> = ({ initial = {} as IDocumentSet, onSubmit }) => {
  const { createDocumentsSet, updateDocumentsSet } = useDocumentsSetActions();
  const { getCompanies } = useCompanyActions();
  const { documentsList } = useDictionary();
  const { user } = useUser();

  const submitHandler = async (values:IDocumentSet) => {
    const action = values.id ? updateDocumentsSet : createDocumentsSet;
    const resp:IError|IDocumentSet = await action(values);

    if ((resp as IError).errors) {
      return deflatObject((resp as IError).errors);
    }

    onSubmit?.(resp);
  };

  return (
    <Form<IDocumentSet>
      onSubmit={submitHandler}
      validate={documentsSetValidate}
      submitBtn="Сохранить"
      initial={initial}
      restoreValues={!initial.id}
    >
      { ({ errors, initial, formChangeTrigger }:IFormRenderProps<IDocumentSet>) => (
        <>
          { initial.id && <Input type="hidden" name="id" defaultValue={initial.id}/> }
          <FormRow>
            <Input
              label="Название"
              name="title"
              errors={errors.title}
              defaultValue={initial.title}/>
            {isAdmin(user) &&
            <Select<ICompany>
              label="Для какой компании"
              name="companyId"
              errors={errors.companyId}
              defaultValue={initial.companyId}
              loadOptions={getCompanies}
              mapOption={mapCompaniesToOption}
              onChange={formChangeTrigger}
            />}
            <Checkbox
              name="personal"
              label="Мой персональный набор"
              defaultChecked={initial.personal}
              withLabelGap
            />
          </FormRow>
          <Delimeter size="m"/>
          <CheckboxGroup label="Укажите входящие документы" errors={errors.docs}>
            { documentsList.map(doc => (
              <Checkbox
                key={doc.docType}
                name="docs[]"
                value={doc.docType}
                label={doc.title || ''}
                defaultChecked={initial?.docs?.includes(doc.docType)}
              />
            )) }
          </CheckboxGroup>
        </>
      )}
    </Form>
  );
};