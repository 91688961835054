import { UserRolesRu } from '%/data/user-roles';
import { IUser } from '%/entities/user';
import { generatePath, getViewAbsoluteUrl } from '~/core/views';
import { IEntitiesList } from '~/shared/components/entities/d';
import { Tiles } from '~/shared/components/tiles';
import { ITile } from '~/shared/components/tiles/d';
import { getUserNameInitials } from '~/shared/tools/get-name-initials';

const castItemToTile = (item:IUser):ITile<IUser> => ({
  id: item.id,
  title: getUserNameInitials(item),
  subtitle: item.phone,
  description: UserRolesRu[item.role],
  url: `${generatePath(getViewAbsoluteUrl('entities', 'edit'), { entity: 'users', id: item.id })}`,
  urlState: { initial: item }
});

export const Users:React.FC<IEntitiesList<IUser>> = ({ items }) => {
  return <Tiles<IUser> items={items.map(item => castItemToTile(item))}/>;
};