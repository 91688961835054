import { ChangeEvent, useEffect, useState } from 'react';

// import { generatePath, useNavigate } from 'react-router-dom';
import { OfferStatuses } from '%/data/offer';
import { IDocument } from '%/entities/document';
import { IDocumentSet } from '%/entities/documents-set';
import { IError } from '%/entities/error';
import { IOffer } from '%/entities/offer';
import { IUser } from '%/entities/user';
import { deflatObject } from '%/utils/flat-object';
import { isAdmin } from '%/utils/user';
import { IValidationErrors } from '%/validation/d';
import { offerValidate } from '%/validation/offer';
// import { getViewAbsoluteUrl } from '~/core/views';
// import { Button } from '~/shared/components/controls/button';
import { Checkbox } from '~/shared/components/controls/checkbox';
import { CheckboxGroup } from '~/shared/components/controls/checkbox-group';
import { Form } from '~/shared/components/controls/form';
import { IFormRenderProps } from '~/shared/components/controls/form/d';
import { FormRow } from '~/shared/components/controls/form/form-row';
import { changeFormValue, getValue } from '~/shared/components/controls/form/utils';
import { Input } from '~/shared/components/controls/input';
import { SelectCompany } from '~/shared/components/controls/select-company';
import { Textarea } from '~/shared/components/controls/textarea';
import { Delimeter } from '~/shared/components/delimeter';
import { IEntityForm } from '~/shared/components/entities/d';
import { useNotification } from '~/shared/components/notification';
import { useDictionary } from '~/shared/hooks/use-dictionary';
import { useDocumentsSetActions } from '~/shared/hooks/use-documents-set-actions';
import { useOfferActions } from '~/shared/hooks/use-offer-actions';
import { useUser } from '~/shared/hooks/use-user';

import { Controls } from './controls';

import styles from './offers.module.styl';

const FORM_ID = 'updateOfferProcess';

export const OfferEditForm:React.FC<IEntityForm<IOffer>> = ({ initial, onSubmit }) => {
  // const navigate = useNavigate();
  const [sets, setDocSets] = useState([]);
  const { user } = useUser();
  const { documentsList } = useDictionary();
  const { updateOffer } = useOfferActions();
  const { addSendingId, removeSendingId } = useNotification();
  const { getDocumentsSets } = useDocumentsSetActions();

  const loadDocumentsSets = async () => {
    const data = await getDocumentsSets();
    if (!data.errorStatus) {
      const mappedData = data.map((set:IDocumentSet) => ({
        ...set,
        mappedDocs: set.docs.map(docType => documentsList.find(doc => doc.docType === docType)?.title)
      }));
      setDocSets(mappedData);
    }
  };

  const handleDocsSetChange = (event:ChangeEvent<HTMLInputElement>) => {
    const checked = getValue(event.target.name);
    const checkedDocs:string[] = [];

    checked.forEach((el:string) => {
      const checkedSets:IDocumentSet|undefined = sets.find((set:IDocumentSet) => set.id === el);
      if (checkedSets) {
        checkedDocs.push(...(checkedSets as IDocumentSet).docs);
      }
    });

    changeFormValue('data.documents[]', checkedDocs);
  };

  const submitHandler = async (values:IOffer) => {
    const reqBody:IOffer = {
      ...initial,
      ...values,
      data: {
        ...initial?.data,
        ...values.data,
        documents: (values.data?.documents?.map(docType => (
          initial?.data?.documents?.find(
            doc => docType === (doc as IDocument).docType
          ) || { docType }) as IDocument
        ))
      }
    };

    addSendingId(FORM_ID);
    const resp:IOffer|IError = await updateOffer(reqBody, false);
    removeSendingId(FORM_ID);

    if ((resp as IError).errors) {
      return deflatObject((resp as IError).errors);
    }

    onSubmit?.(resp);
  };

  // const handleOfferStatusChange = async (status: keyof typeof OfferStatuses) => {
  //   addSendingId(FORM_ID);
  //   const resp:IOffer|IError = await updateOfferStatus(initial?.id as string, status);
  //   removeSendingId(FORM_ID);

  //   if ((resp as IError).errors) {
  //     return deflatObject((resp as IError).errors);
  //   }
  //   navigate(generatePath(getViewAbsoluteUrl('entities', 'list'), { entity: 'offers' }));
  // };
  // const cancelOffer = () => handleOfferStatusChange(OfferStatuses.Closed);
  // const returnToInProgressOffer = () => handleOfferStatusChange(OfferStatuses.InProgress);

  const isCandidateDisabled = !!initial?.id;
  const isOfferEditDisabled = initial?.id &&
    (initial?.status === OfferStatuses.Completed || initial?.status === OfferStatuses.Closed);

  const validate = (values:IOffer):IValidationErrors<IOffer> => {
    const data:IOffer = {
      ...values,
      data: {
        ...values.data,
        documents: values.data?.documents?.map(docType => ({ docType })) as IDocument[]
      }
    };
    return offerValidate(data, documentsList);
  };

  useEffect(() => {
    documentsList?.length && loadDocumentsSets();
  }, [documentsList]);

  return (
    <>
    {!!initial?.id &&
      <div className={styles.buttons}>
        <div/>
        {/* { !isOfferEditDisabled && isAdmin(user) && (
          <Button bordered accent text="Отменить заявку" size="s" onClick={cancelOffer}/>
        )}
        { isOfferEditDisabled && isAnyHr(user) && (
          <Button bordered accent text="Вернуть в работу" size="s" onClick={returnToInProgressOffer}/>
        )} */}
        <div className={styles.controls}>
          <Controls offer={initial} user={user as IUser}/>
        </div>
      </div>
    }
    <Delimeter size="m"/>
    <Form<IOffer>
      onSubmit={submitHandler}
      validate={validate}
      submitBtn={initial?.id ? 'Сохранить' : 'Сгенерировать ссылку'}
      formId={FORM_ID}
      initial={initial}
      disabled={!!isOfferEditDisabled}
    >
      { ({ errors, initial, formChangeTrigger, values }:IFormRenderProps<IOffer>) => (
        <>
          <Input
            type="hidden"
            name="type"
            defaultValue={initial.type || 'documents'}
          />
          {!isAdmin(user) &&
            <Input
              type="hidden"
              name="companyId"
              defaultValue={initial.companyId || user?.workCompany?.id}
            />
          }
          <FormRow part="1-2">
            <Checkbox
              name="template"
              label="Доступен по общей ссылке"
              defaultChecked={initial.template}
              disabled
              withLabelGap
            />
            { values.template ?
              <Input
                label="Заголовок"
                name="title"
                errors={errors.title}
                defaultValue={initial.title}/>
              : null
            }
          </FormRow>
          {!values.template &&
          <>
            <Delimeter size="m"/>
            <FormRow>
              <Input
                label="Фамилия"
                name="data.lastName"
                //@ts-ignores
                errors={errors.data?.lastName}
                defaultValue={initial.data?.lastName}
                disabled={isCandidateDisabled || values.template}/>
              <Input
                label="Имя"
                name="data.firstName"
                //@ts-ignores
                errors={errors.data?.firstName}
                defaultValue={initial.data?.firstName}
                disabled={isCandidateDisabled || values.template}/>
              <Input
                label="Отчество"
                name="data.middleName"
                defaultValue={initial.data?.middleName}
                disabled={isCandidateDisabled || values.template}/>
            </FormRow>
          </>}
          <Delimeter size="m"/>
          <FormRow>
            {!values.template &&
            <>
              <Input
                label="Телефон"
                name="data.phone"
                //@ts-ignores
                errors={errors.data?.phone}
                mask="+7 999 999-99-99"
                defaultValue={initial.data?.phone}
                disabled={isCandidateDisabled || values.template}/>
              <Input
                label="Email"
                name="data.email"
                //@ts-ignores
                errors={errors.data?.email}
                defaultValue={initial.data?.email}
                disabled={isCandidateDisabled || values.template}/>
            </>}
            {isAdmin(user) ?
            <SelectCompany<IOffer>
              label="Компания"
              name="companyId"
              errors={errors.companyId}
              defaultValue={initial.companyId}
              onChange={formChangeTrigger}
              disabled={!!isOfferEditDisabled}
              entityFrom="offers"
            /> :
            <div/>}
          </FormRow>
          <Delimeter size="m"/>
          <Textarea
            label="Комментарий"
            name="comment"
            placeholder="Информация из данного поля не видна кандидату"
            defaultValue={initial.comment}
            disabled={!!isOfferEditDisabled}
          />
          <Delimeter size="m"/>
          <FormRow>
            {/* @ts-ignore */}
            <CheckboxGroup label="Документы для загрузки" errors={errors.data?.documents}>
              { documentsList.filter(doc => !doc.disabled).map(doc => (
                <Checkbox
                  key={doc.docType}
                  name="data.documents[]"
                  value={doc.docType}
                  label={doc.title || ''}
                  defaultChecked={!!initial.data?.documents?.find(document => (document as IDocument).docType === doc.docType)}
                  disabled={!!isOfferEditDisabled}
                />
              )) }
            </CheckboxGroup>
            {!!sets.length &&
            <CheckboxGroup label="Использовать набор документов">
              { sets.map((docSet:IDocumentSet) => (
                <Checkbox
                  key={docSet.id}
                  name="sets[]"
                  value={docSet.id}
                  label={docSet.title}
                  note={docSet.mappedDocs?.join(', ')}
                  onChange={handleDocsSetChange}
                  disabled={!!isOfferEditDisabled}
                />
              )) }
            </CheckboxGroup>
            }
          </FormRow>
        </>
      )}
    </Form>
    </>
  );
};